.content_style h1,
.content_style h2,
.content_style h3,
.content_style ul,
.content_style table,
.content_style .button {
  margin-bottom: 20px;
}
.content_style p {
  margin-bottom: 20px;
}
.content_style .perex {
  font-size: 20px;
  font-family: montserratsemibold, arial, Helvetica, sans-serif;
}
.content_style ul li {
  padding: 7px 0 7px 0;
  list-style-position: inside;
  list-style-image: url("/images/list_style.jpg");
}
.content_style table {
  padding: 0;
  table-layout: fixed;
  border: 1px solid #d5d5c7;
  border-collapse: collapse;
}
.content_style tr:nth-child(odd) td {
  background-color: #f5f5f5;
}
.content_style tr:nth-child(even) {
  background-color: #ddd;
}
.content_style th {
  padding: 15px;
  color: #ffffff;
  background-color: #70aa40;
  border: none;
}
.content_style td {
  padding: 10px 25px;
  border-right: 1px solid #d5d5c7;
}
.content_style td:last-child {
  border-right: none;
}
.content_style .imgright {
  margin-bottom: 15px;
}
@media only screen and (min-width: 800px) {
  .content_style .imgright {
    float: right;
    margin: 0 0 15px 15px;
  }
}
@media only screen and (min-width: 800px) {
  .content_style .imgleft {
    float: left;
    margin: 0 15px 15px 0;
  }
}
.content_style .imgline {
  position: relative;
  display: inline-block;
  margin: 0 50px 50px 0;
}
.content_style .imgline img {
  margin: 0;
}
.content_style .imgline:after {
  position: absolute;
  right: -18px;
  bottom: -18px;
  z-index: -1;
  display: block;
  width: 50%;
  height: 50%;
  background-color: #f7a810;
  content: "";
}
.gallery {
  width: 100%;
  margin: 20px 0;
}
.gallery .item {
  margin: 5px;
  padding: 5px;
  border: 1px solid #bebebe;
  transition: border-color 0.4s ease-in-out;
}
.gallery .item:hover {
  border: 1px solid #f7a810;
}
.filter {
  padding: 5px 25px;
  background-color: #faf6f1;
  border: 1px solid #e8e3dd;
}
.filter__item {
  margin: 10px 0;
}
.video {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  /* Udává poměr stran 16:9 */
}
.videofull {
  width: 100%;
}
.video__iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=css/content-style.css.map */